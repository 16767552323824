export const TRANSLATION_UPDATED_CARD = 'UPDATED_CARD';
export const TRANSLATION_APP_STATUS_CONFIRMED_BY = 'APP_STATUS_CONFIRMED_BY';
export const TRANSLATION_APP_STATUS_MISSING_INFO = 'APP_STATUS_MISSING_INFO';
export const TRANSLATION_APP_STATUS_LAST_UPDATED = 'APP_STATUS_LAST_UPDATED';
export const TRANSLATION_APP_STATUS_BY = 'APP_STATUS_BY';
export const TRANSLATION_APP_STATUS_CONFIRMED_BTN = 'APP_STATUS_CONFIRMED_BTN';
export const TRANSLATION_APP_STATUS_INFO_COMPLEATED =
    'APP_STATUS_INFO_COMPLEATED';
export const TRANSLATION_APP_STATUS_RECONFIRM_BTN = 'APP_STATUS_RECONFIRM_BTN';
export const TRANSLATION_APP_STATUS_PERIODIC_UPDATE =
    'APP_STATUS_PERIODIC_UPDATE';
export const TRANSLATION_APP_STATUS_LAST_CONFIRMED_BY =
    'APP_STATUS_LAST_CONFIRMED_BY';
export const TRANSLATION_APP_STATUS_PERIODIC_UPDATE_DUE =
    'APP_STATUS_PERIODIC_UPDATE_DUE';
export const TRANSLATION_APP_STATUS_NEXT_REVIEW_DATE =
    'APP_STATUS_NEXT_REVIEW_DATE';
export const TRANSLATION_APP_STATUS_SET_DUE_DATE = 'APP_STATUS_SET_DUE_DATE';
export const TRANSLATION_CASE_SLA_DUE = 'CASE_SLA_DUE';
export const TRANSLATION_CASE_NO_SLA = 'CASE_NO_SLA';
export const TRANSLATION_CASE_SLA_OVERDUE = 'CASE_SLA_OVERDUE';
export const TRANSLATION_CASE_COMPLEATED = 'CASE_COMPLEATED';
export const TRANSLATION_CASE_COMPLEATED_WITHIN_SLA =
    'CASE_COMPLEATED_WITHIN_SLA';
export const TRANSLATION_CASE_COMPLEATED_OUTSIDE_SLA =
    'CASE_COMPLEATED_OUTSIDE_SLA';
export const TRANSLATION_CASEWORK_STATUS_COMPLETE = 'CASEWORK_STATUS_COMPLETE';
export const TRANSLATION_CASEWORK_STATUS_HAS_ISSUE =
    'CASEWORK_STATUS_HAS_ISSUE';
export const TRANSLATION_CASEWORK_STATUS_OVERDUE = 'CASEWORK_STATUS_OVERDUE';
export const TRANSLATION_CASEWORK_STATUS_DUE_SOON = 'CASEWORK_STATUS_DUE_SOON';
export const TRANSLATION_CASEWORK_STATUS_DUE_LATER =
    'CASEWORK_STATUS_DUE_LATER';
export const TRANSLATION_CASEWORK_STATUS_NO_DEADLINE =
    'CASEWORK_STATUS_NO_DEADLINE';
export const TRANSLATION_CASEWORK_STATUS_CANCELLED =
    'CASEWORK_STATUS_CANCELLED';
export const TRANSLATION_CASE_NEXT_ACTION = 'CASE_NEXT_ACTION';
export const TRANSLATION_CASE_ALL_ACTIONS_ARE_CANCELLED =
    'CASE_ALL_ACTIONS_ARE_CANCELLED';
export const TRANSLATION_CASE_ALL_ACTIONS_COMPLEATED =
    'CASE_ALL_ACTIONS_COMPLEATED';
export const TRANSLATION_APP_CASE_CREATED = 'CASE_CREATED';
export const TRANSLATION_OPEN_IN_NEW_TAB = 'OPEN_IN_NEW_TAB';
export const TRANSLATION_ASSIGN_TO = 'ASSIGN_TO';
export const TRANSLATION_DELEGATE_ACCESS = 'DELEGATE_ACCESS';
export const TRANSLATION_CANCEL = 'CANCEL';
export const TRANSLATION_AREA_RECENTRECORDS = 'AREA_RECENTRECORDS';
export const TRANSLATION_NO_RECENT_RECORDS = 'NO_RECENT_RECORDS';
export const TRANSLATION_SVG_TEXT_NO_RECORD_FOUND = 'SVG_TEXT_NO_RECORD_FOUND';
export const TRANSLATION_ENUM_TABTYPE_KYCSTATUS = 'ENUM_TABTYPE_KYCSTATUS';
export const TRANSLATION_UPDATE_KYC_BTN = 'UPDATE_KYC_BTN';
export const TRANSLATION_LINK_PENDINGCHANGES = 'LINK_PENDINGCHANGES';
export const TRANSLATION_CREATE_NEW = 'CREATE_NEW';
export const TRANSLATION_ENTITY_SAVEDFILTER_PLURAL =
    'ENTITY_SAVEDFILTER_PLURAL';
export const TRANSLATION_MENU_SEEMORE = 'MENU_SEEMORE';
export const TRANSLATION_MENU_SEE_LESS = 'MENU_SEE_LESS';
export const TRANSLATION_USER_FEEDBACK_NO_RESULTS = 'USER_FEEDBACK_NO_RESULTS';
export const TRANSLATION_ENTITY_TEAM_SINGULAR = 'ENTITY_TEAM_SINGULAR';
export const TRANSLATION_ENUM_TABTYPE_TEAMMEMBERS = 'ENUM_TABTYPE_TEAMMEMBERS';
export const TRANSLATION_SVG_TEXT_NO_TEAM_MEMBERS = 'SVG_TEXT_NO_TEAM_MEMBERS';
export const TRANSLATION_ENTITY_OPPORTUNITY_PLURAL =
    'ENTITY_OPPORTUNITY_PLURAL';
export const TRANSLATION_SEE_ALL_OPPORTUNITIES_BTN =
    'SEE_ALL_OPPORTUNITIES_BTN';
export const TRANSLATION_WELCOME_SUBTITLE = 'WELCOME_SUBTITLE';
export const TRANSLATION_COMPLETE_CASE_BTN = 'COMPLETE_CASE_BTN';
export const TRANSLATION_PARENT_CASE_BADGE = 'PARENT_CASE_BADGE';
export const TRANSLATION_CASE_COMPLATED_DATE = 'CASE_COMPLATED_DATE';
export const TRANSLATION_CASE_ALL_ACTIONS_CANCELLED =
    'CASE_ALL_ACTIONS_CANCELLED';
export const TRANSLATION_ADD_ACTIVITY_BTN = 'ADD_ACTIVITY_BTN';
export const TRANSLATION_ENUM_TABTYPE_WORK_LIST = 'ENUM_TABTYPE_WORK_LIST';
export const TRANSLATION_ENUM_TABTYPE_CASE_DETAILS =
    'ENUM_TABTYPE_CASE_DETAILS';
export const TRANSLATION_ENUM_TABTYPE_RELATED_RECORDS =
    'ENUM_TABTYPE_RELATED_RECORDS';
export const TRANSLATION_ENUM_TABTYPE_DOCUMENTS = 'ENUM_TABTYPE_DOCUMENTS';
export const TRANSLATION_ENUM_TABTYPE_TIMELINE = 'ENUM_TABTYPE_TIMELINE';
export const TRANSLATION_ENTITY_NOTE_PLURAL = 'ENTITY_NOTE_PLURAL';
export const TRANSLATION_SEE_ALL_LIST_MEMBERSHIPS_BTN =
    'SEE_ALL_LIST_MEMBERSHIPS_BTN';
export const TRANSLATION_ENTITY_CLIENTPRODUCT_PLURAL =
    'ENTITY_CLIENTPRODUCT_PLURAL';
export const TRANSLATION_SEE_ALL_CASES_BTN = 'SEE_ALL_CASES_BTN';
export const TRANSLATION_ENTITY_DOCUMENT_PLURAL = 'ENTITY_DOCUMENT_PLURAL';
export const TRANSLATION_ENTITY_CASE_PLURAL = 'ENTITY_CASE_PLURAL';
export const TRANSLATION_SEE_ALL_DOCUMENTS_BTN = 'SEE_ALL_DOCUMENTS_BTN';
export const TRANSLATION_ENTITY_FEED_PLURAL = 'ENTITY_FEED_PLURAL';
export const TRANSLATION_LABEL_MEMBERSHIPS = 'LABEL_MEMBERSHIPS';
export const TRANSLATION_LABEL_PORTFOLIO_INSIGHTS = 'LABEL_PORTFOLIO_INSIGHTS';
export const TRANSLATION_FILTER_PERSONAL_VIEW = 'FILTER_PERSONAL_VIEW';
export const TRANSLATION_FILTER_SYSTEM_VIEW = 'FILTER_SYSTEM_VIEW';
export const TRANSLATION_SEARCH_CASES = 'SEARCH_CASES';
export const TRANSLATION_ENTITY_CASE_SINGULAR = 'ENTITY_CASE_SINGULAR';
export const TRANSLATION_SORT_MENU_CREATED_ON = 'SORT_MENU_CREATED_ON';
export const TRANSLATION_SORT_DUE = 'SORT_MENU_DUE';
export const TRANSLATION_SORT_MENU_NEXT_ACTION_DUE =
    'SORT_MENU_NEXT_ACTION_DUE';
export const TRANSLATION_WORK_LIST_SELECT_ITEM = 'WORK_LIST_SELECT_ITEM';
export const TRANSLATION_WORK_LIST_ALL_ACTIONS_CANCELLED =
    'WORK_LIST_ALL_ACTIONS_CANCELLED';
export const TRANSLATION_WORK_LIST_CASE_COMPLEATED_SELECT_ITEM =
    'WORK_LIST_CASE_COMPLEATED_SELECT_ITEM';
export const TRANSLATION_GROUP_BY = 'GROUP_BY';
export const TRANSLATION_GROUP_BY_MENU_STAGE = 'GROUP_BY_MENU_STAGE';
export const TRANSLATION_GROUP_BY_MENU_ASSIGNEE = 'GROUP_BY_MENU_ASSIGNEE';
export const TRANSLATION_GROUP_BY_MENU_STATUS_DETAIL =
    'GROUP_BY_MENU_STATUS_DETAIL';
export const TRANSLATION_GROUP_BY_MENU_STATUS = 'GROUP_BY_MENU_STATUS';
export const TRANSLATION_GROUP_BY_MENU_RELATED_RECORD =
    'GROUP_BY_MENU_RELATED_RECORD';
export const TRANSLATION_ACTIVITY_CREATED = 'ACTIVITY_CREATED';
export const TRANSLATION_ACTIVITY_COMPLEATED = 'ACTIVITY_COMPLEATED';
export const TRANSLATION_ACTIVITY_NOT_FILED = 'ACTIVITY_NOT_FILED';
export const TRANSLATION_TAB_COMMENTS = 'TAB_COMMENTS';
export const TRANSLATION_TAB_CHAT = 'TAB_CHAT';
export const TRANSLATION_TAB_NOTES = 'TAB_NOTES';
export const TRANSLATION_TAB_CONTENT = 'TAB_CONTENT';
export const TRANSLATION_DOCK_ACTIVITY = 'DOCK_ACTIVITY';
export const TRANSLATION_ACTIVITY_NO_CHAT = 'ACTIVITY_NO_CHAT';
export const TRANSLATION_STATUS_TAG_OVERDUE = 'STATUS_TAG_OVERDUE';
export const TRANSLATION_STATUS_TAG_CANCELLED = 'STATUS_TAG_CANCELLED';
export const TRANSLATION_STATUS_TAG_RECEIVED = 'STATUS_TAG_RECEIVED';
export const TRANSLATION_STATUS_TAG_COMPLETE = 'STATUS_TAG_COMPLETE';
export const TRANSLATION_STATUS_TAG_ENDED = 'STATUS_TAG_ENDED';
export const TRANSLATION_STATUS_TAG_OPEN = 'STATUS_TAG_OPEN';
export const TRANSLATION_STATUS_TAG_SENT = 'STATUS_TAG_SENT';
export const TRANSLATION_STATUS_TAG_DRAFT = 'STATUS_TAG_DRAFT';
export const TRANSLATION_STATUS_TAG_CONFIRMED = 'STATUS_TAG_CONFIRMED';
export const TRANSLATION_STATUS_TAG_SUBMITTED = 'STATUS_TAG_SUBMITTED';
export const TRANSLATION_STATUS_TAG_NOT_STARTED = 'STATUS_TAG_NOT_STARTED';
export const TRANSLATION_STATUS_TAG_READY = 'STATUS_TAG_READY';
export const TRANSLATION_STATUS_TAG_CANNOT_START = 'STATUS_TAG_CANNOT_START';
export const TRANSLATION_SELECT_AN_ACTIVITY = 'SELECT_AN_ACTIVITY';
export const TRANSLATION_BACK_TO_ACTIVITY = 'BACK_TO_ACTIVITY';
export const TRANSLATION_CLOSE_POPOVER = 'CLOSE_POPOVER';
export const TRANSLATION_RELATED_PARTY_POPOVER = 'RELATED_PARTY_POPOVER';
export const TRANSLATION_ACTIVITY_FORM_LABEL = 'ACTIVITY_FORM_LABEL';
export const TRANSLATION_COMMENTS_LABEL = 'COMMENTS_LABEL';
export const TRANSLATION_NOTES_LABEL = 'NOTES_LABEL';
export const TRANSLATION_LINK_RELATED_LABEL = 'LINK_RELATED_LABEL';
export const TRANSLATION_ENTITY_ACTIVITY_SINGULAR = 'ENTITY_ACTIVITY_SINGULAR';
export const TRANSLATION_ENTITY_OPPORTUNITY_SINGULAR =
    'ENTITY_OPPORTUNITY_SINGULAR';
export const TRANSLATION_ENTITY_ARTICLE_PLURAL = 'ENTITY_ARTICLE_PLURAL';
export const TRANSLATION_MORE_LABEL = 'MORE_LABEL';
export const TRANSLATION_SORT_BY = 'SORT_BY';
export const TRANSLATION_SORT_MENU_CLIENT_VALUE = 'SORT_MENU_CLIENT_VALUE';
export const TRANSLATION_SORT_MENU_URGENCY = 'SORT_MENU_URGENCY';
export const TRANSLATION_SORT_MENU_ESTIMATED_CLOSE =
    'SORT_MENU_ESTIMATED_CLOSE';
export const TRANSLATION_SORT_MENU_VALUE = 'SORT_MENU_VALUE';
export const TRANSLATION_SORT_MENU_NAME = 'SORT_MENU_NAME';
export const TRANSLATION_SORT_MENU_RECENTLY_ADDED = 'SORT_MENU_RECENTLY_ADDED';
export const TRANSLATION_SORT_MENU_ALPHABETICAL = 'SORT_MENU_ALPHABETICAL';
export const TRANSLATION_SORT_MENU_DATE_PUBLISHED = 'SORT_MENU_DATE_PUBLISHED';
export const TRANSLATION_SORT_MENU_RECENTLY_UPLOADED =
    'SORT_MENU_RECENTLY_UPLOADED';
export const TRANSLATION_SORT_MENU_ALPHABETICAL_A_Z =
    'SORT_MENU_ALPHABETICAL_A_Z';
export const TRANSLATION_SORT_MENU_ALPHABETICAL_Z_A =
    'SORT_MENU_ALPHABETICAL_Z_A';
export const TRANSLATION_SEARCH_OPPORTUNITIES = 'SEARCH_OPPORTUNITIES';
export const TRANSLATION_ENTITY_DOCUMENT_SINGULAR = 'ENTITY_DOCUMENT_SINGULAR';
export const TRANSLATION_ENTITY_NOTE_SINGULAR = 'ENTITY_NOTE_SINGULAR';
export const TRANSLATION_SEARCH_TASKS = 'SEARCH_TASKS';
export const TRANSLATION_ENTITY_TASK_PLURAL = 'ENTITY_TASK_PLURAL';
export const TRANSLATION_ENTITY_TASK_SINGULAR = 'ENTITY_TASK_SINGULAR';
export const TRANSLATION_INACTIVE_RECORD = 'INACTIVE_RECORD';
export const TRANSLATION_SUGGESTED_CASES = 'SUGGESTED_CASES';
export const TRANSLATION_RELATED_CASES_GUIDANCE_TEXT =
    'RELATED_CASES_GUIDANCE_TEXT';
export const TRANSLATION_RELATED_CASES_OPEN = 'RELATED_CASES_OPEN';
export const TRANSLATION_GUIDANCE_TEXT_RELATED_CASES =
    'GUIDANCE_TEXT_RELATED_CASES';
export const TRANSLATION_SUGGESTED_OPPORTUNITIES = 'SUGGESTED_OPPORTUNITIES';
export const TRANSLATION_RELATED_OPP_GUIDANCE_TEXT =
    'RELATED_OPP_GUIDANCE_TEXT';
export const TRANSLATION_TOAST_MESSAGE_RECORD_CREATED =
    'TOAST_MESSAGE_RECORD_CREATED';
export const TRANSLATION_TOAST_MESSAGE_CLICK_TO_VIEW =
    'TOAST_MESSAGE_CLICK_TO_VIEW';
export const TRANSLATION_TOAST_MESSAGE_SUCCESSFULLY =
    'TOAST_MESSAGE_SUCCESSFULLY';
export const TRANSLATION_TOAST_MESSAGE_DOC_TEMPALTE_GENERATED_ERROR =
    'TOAST_MESSAGE_DOC_TEMPALTE_GENERATED_ERROR';
export const TRANSLATION_TOAST_MESSAGE_TEAM_MEMBER_DELETED_HAS_ERROR =
    'TOAST_MESSAGE_TEAM_MEMBER_DELETED_HAS_ERROR';
export const TRANSLATION_TOAST_MESSAGE_REMOVE_PRIMARY_TEAM_MEMBER =
    'TOAST_MESSAGE_REMOVE_PRIMARY_TEAM_MEMBER';
export const TRANSLATION_TOAST_MESSAGE_DOC_UPDATED_SUCCESSFULLY =
    'TOAST_MESSAGE_DOC_UPDATED_SUCCESSFULLY';
export const TRANSLATION_TOAST_MESSAGE_DOC_DELETED_SUCCESSFULLY =
    'TOAST_MESSAGE_DOC_DELETED_SUCCESSFULLY';
export const TRANSLATION_TOAST_MESSAGE_DOC_DELETED_HAS_ERROR =
    'TOAST_MESSAGE_DOC_DELETED_HAS_ERROR';
export const TRANSLATION_TOAST_MESSAGE_SUCCESSFULLY_ASSIGNED =
    'TOAST_MESSAGE_SUCCESSFULLY_ASSIGNED';
export const TRANSLATION_TOAST_MESSAGE_ADD_TO_LIST_SUCCESS =
    'TOAST_MESSAGE_ADD_TO_LIST_SUCCESS';
export const TRANSLATION_TOAST_MESSAGE_DASHBOARD_CLONED =
    'TOAST_MESSAGE_DASHBOARD_CLONED';
export const TRANSLATION_TOAST_MESSAGE_ID_VERIFICATION =
    'TOAST_MESSAGE_ID_VERIFICATION';
export const TRANSLATION_TOAST_MESSAGE_EXPORT_BACKGROUND_JOB =
    'TOAST_MESSAGE_EXPORT_BACKGROUND_JOB';
export const TRANSLATION_TOAST_MESSAGE_ACTION_COMPLEATED_SUCCESSFULLY =
    'TOAST_MESSAGE_ACTION_COMPLEATED_SUCCESSFULLY';
export const TRANSLATION_TOAST_MESSAGE_IMPORT_FILE_UNSUCCESSFUL =
    'TOAST_MESSAGE_IMPORT_FILE_UNSUCCESSFUL';
export const TRANSLATION_TOAST_MESSAGE_BACKGROUND_JOB_GENERATED =
    'TOAST_MESSAGE_BACKGROUND_JOB_GENERATED';
export const TRANSLATION_TOAST_MESSAGE_OPP_DELETED =
    'TOAST_MESSAGE_OPP_DELETED';
export const TRANSLATION_TOAST_MESSAGE_TEAM_MEMBER_REMOVED =
    'TOAST_MESSAGE_TEAM_MEMBER_REMOVED';
export const TRANSLATION_TOAST_MESSAGE_PASSWORD_CHANGED =
    'TOAST_MESSAGE_PASSWORD_CHANGED';
export const TRANSLATION_TOAST_MESSAGE_CONSTENT_ERROR =
    'TOAST_MESSAGE_CONSTENT_ERROR';
export const TRANSLATION_TOAST_MESSAGE_ACCESS_GRANTED =
    'TOAST_MESSAGE_ACCESS_GRANTED';
export const TRANSLATION_TOAST_MESSAGE_RECORD_DELETED =
    'TOAST_MESSAGE_RECORD_DELETED';
export const TRANSLATION_TOAST_MESSAGE_ACTIVITY_CANCELLED =
    'TOAST_MESSAGE_ACTIVITY_CANCELLED';
export const TRANSLATION_TOAST_MESSAGE_GENERATE_DOC_TEMPLATE =
    'TOAST_MESSAGE_GENERATE_DOC_TEMPLATE';
export const TRANSLATION_TOAST_MESSAGE_DOC_TEMPALTE_GENERATED_SUCCESSFULLY =
    'TOAST_MESSAGE_DOC_TEMPALTE_GENERATED_SUCCESSFULLY';
export const TRANSLATION_TOAST_MESSAGE_DOC_SUCCESSFULLY_DELETED =
    'TOAST_MESSAGE_DOC_SUCCESSFULLY_DELETED';
export const TRANSLATION_TOAST_MESSAGE_IMPORT_LOG_DELETED =
    'TOAST_MESSAGE_IMPORT_LOG_DELETED';
export const TRANSLATION_TOAST_MESSAGE_IMPORT_LOG_DELETED_ERROR =
    'TOAST_MESSAGE_IMPORT_LOG_DELETED_ERROR';
export const TRANSLATION_TOAST_MESSAGE_DOC_TEMPLATE_UPDATED_STATUS =
    'TOAST_MESSAGE_DOC_TEMPLATE_UPDATED_STATUS';
export const TRANSLATION_TOAST_MESSAGE_DOC_TEMPLATE_DELETED =
    'TOAST_MESSAGE_DOC_TEMPLATE_DELETED';
export const TRANSLATION_TOAST_MESSAGE_FILE_PARSED_SUCCESSFULLY =
    'TOAST_MESSAGE_FILE_PARSED_SUCCESSFULLY';
export const TRANSLATION_TOAST_MESSAGE_ACTION_COMPLEATED =
    'TOAST_MESSAGE_ACTION_COMPLEATED';
export const TRANSLATION_TOAST_MESSAGE_SENDING_MESSAGE =
    'TOAST_MESSAGE_SENDING_MESSAGE';
export const TRANSLATION_TOAST_MESSAGE_SUCCESSFULLY_SENT =
    'TOAST_MESSAGE_SUCCESSFULLY_SENT';
export const TRANSLATION_TOAST_MESSAGE_SENDING_ERROR =
    'TOAST_MESSAGE_SENDING_ERROR';
export const TRANSLATION_TOAST_MESSAGE_REPLYING = 'TOAST_MESSAGE_REPLYING';
export const TRANSLATION_TOAST_MESSAGE_REPLY_SENT = 'TOAST_MESSAGE_REPLY_SENT';
export const TRANSLATION_TOAST_MESSAGE_DOC_MARKED_AS_SENT =
    'TOAST_MESSAGE_DOC_MARKED_AS_SENT';
export const TRANSLATION_TOAST_MESSAGE_DOC_ATTACHED_TO_CHECKLIST =
    'TOAST_MESSAGE_DOC_ATTACHED_TO_CHECKLIST';
export const TRANSLATION_TOAST_MESSAGE_DOC_REMOVED_FROM_CHECKLIST =
    'TOAST_MESSAGE_DOC_REMOVED_FROM_CHECKLIST';
export const TRANSLATION_SHOW_LABEL = 'SHOW_LABEL';
export const TRANSLATION_RESULTS_LABEL = 'RESULTS_LABEL';
export const TRANSLATION_SHOWING_TABLE_PAGING = 'SHOWING_TABLE_PAGING';
export const TRANSLATION_RECORD_DETAILS_LABEL = 'RECORD_DETAILS_LABEL';
export const TRANSLATION_UNKNOWN_CONTACT_LABEL = 'UNKNOWN_CONTACT_LABEL';
export const TRANSLATION_LAST_UPDATED_LABEL = 'LAST_UPDATED_LABEL';
export const TRANSLATION_ENUM_EXTERNALTASKSTATUSTYPE_NOTSTARTED =
    'ENUM_EXTERNALTASKSTATUSTYPE_NOTSTARTED';
export const TRANSLATION_SEARCH_LABEL = 'SEARCH_LABEL';
export const TRANSLATION_TABLE_HEADER_LIFECYCLE_STAGE =
    'TABLE_HEADER_LIFECYCLE_STAGE';
export const TRANSLATION_TABLE_HEADER_NAME = 'TABLE_HEADER_NAME';
export const TRANSLATION_TABLE_HEADER_TYPE = 'TABLE_HEADER_TYPE';
export const TRANSLATION_TABLE_HEADER_SUB_TYPE = 'TABLE_HEADER_SUB_TYPE';
export const TRANSLATION_TABLE_HEADER_LAST_CONTACTED =
    'TABLE_HEADER_LAST_CONTACTED';
export const TRANSLATION_TABLE_HEADER_NEXT_ACTIVITY =
    'TABLE_HEADER_NEXT_ACTIVITY';
export const TRANSLATION_TABLE_HEADER_OPEN_CASES = 'TABLE_HEADER_OPEN_CASES';
export const TRANSLATION_TABLE_HEADER_PIPELINE = 'TABLE_HEADER_PIPELINE';
export const TRANSLATION_TABLE_HEADER_AUM = 'TABLE_HEADER_AUM';
export const TRANSLATION_TABLE_HEADER_LAST_REVIEW = 'TABLE_HEADER_LAST_REVIEW';
export const TRANSLATION_TABLE_HEADER_NEXT_REVIEW = 'TABLE_HEADER_NEXT_REVIEW';
export const TRANSLATION_TABLE_HEADER_COMPLEATED_ACTIVITIES =
    'TABLE_HEADER_COMPLEATED_ACTIVITIES';
export const TRANSLATION_TABLE_HEADER_PARTY_TYPE = 'TABLE_HEADER_PARTY_TYPE';
export const TRANSLATION_TABLE_HEADER_INVESTABLE_ASSETS =
    'TABLE_HEADER_INVESTABLE_ASSETS';
export const TRANSLATION_TABLE_HEADER_LEVEL = 'TABLE_HEADER_LEVEL';
export const TRANSLATION_TABLE_HEADER_TOTAL_VALUE_INTRODUCED =
    'TABLE_HEADER_TOTAL_VALUE_INTRODUCED';
export const TRANSLATION_TABLE_HEADER_TOTAL_VALUE_MANAGED =
    'TABLE_HEADER_TOTAL_VALUE_MANAGED';
export const TRANSLATION_SHOWING_LABEL = 'SHOWING_LABEL';
export const TRANSLATION_TO_LABEL = 'TO_LABEL';
export const TRANSLATION_OF_LABEL = 'OF_LABEL';
export const TRANSLATION_TABLE_HEADER_DOC_CATEGORY =
    'TABLE_HEADER_DOC_CATEGORY';
export const TRANSLATION_TABLE_HEADER_DOC_TYPE = 'TABLE_HEADER_DOC_TYPE';
export const TRANSLATION_TABLE_HEADER_DOC_NAME = 'TABLE_HEADER_DOC_NAME';
export const TRANSLATION_TABLE_HEADER_START_DATE_VALIDITY =
    'TABLE_HEADER_START_DATE_VALIDITY';
export const TRANSLATION_TABLE_HEADER_END_DATE_VALIDITY =
    'TABLE_HEADER_END_DATE_VALIDITY';
export const TRANSLATION_TABLE_HEADER_SIGN_DATE = 'TABLE_HEADER_SIGN_DATE';
export const TRANSLATION_TABLE_HEADER_FILE_NAME = 'TABLE_HEADER_FILE_NAME';
export const TRANSLATION_TABLE_HEADER_DATE_UPDATED =
    'TABLE_HEADER_DATE_UPDATED';
export const TRANSLATION_TABLE_HEADER_UPLOADED_BY = 'TABLE_HEADER_UPLOADED_BY';
export const TRANSLATION_AREA_HOME = 'AREA_HOME';
